import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const tracking = window.location.pathname
var userData = null;
if (tracking == '/search-tracking' || tracking == '/tracking-login') {
    userData = JSON.parse(localStorage.getItem('userInfo'))
} else {
    userData = JSON.parse(localStorage.getItem('userData'))
}

const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
